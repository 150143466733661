import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"
import Hero from "../components/hero"

import { Box, Text, Heading, Paragraph, Tabs, Tab } from "grommet"

const AngledBox = styled(Box)`
  position: relative;
  padding-top: 10px;
  &:before {
    content: "";
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    top: 0;

    clip-path: polygon(100% 100%, 0 0%, 50% 0, 100% 0);
    background: var(--light-gray);
  }
`

const AboutUsPage = () => (
  <Layout>
    <Seo title="Overview" />
    <Hero
      src="/blue-container-ship-in-harbour-from-front-on-water-level.webp"
      left={
        <Box className="body">
          <Heading>EcoMATE™ Cloud</Heading>
          <Text>Web reporting solution for seagoing vessels.</Text>
          <ul>
            <li>
              Centralised web reporting tool for vessels running the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://krohne.com/en/solutions/monitoring-solutions/ecomate-fuel-emission-monitoring-seagoing-vessels"
              >
                EcoMATE system
              </a>
            </li>
            <li>
              Automatic transmission of data to shore for fleet reporting and
              monitoring
            </li>
            <li>Safe access to actual measured data from vessels</li>
            <li>
              Easy download and analysis of fleet reporting and monitoring data
            </li>
          </ul>
        </Box>
      }
    ></Hero>

    <Box background="accent-4">
      <Container className="body" margin={{ top: "large", bottom: "large" }}>
        <Heading level={2}>Overview</Heading>

        <Paragraph>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://krohne.com/en/solutions/monitoring-solutions/ecomate-fuel-emission-monitoring-seagoing-vessels"
          >
            EcoMATE
          </a>
          , the vessel performance system and fleet operation optimization
          software from KROHNE, was designed to be a complete turnkey solution
          for real-time monitoring of fuel consumption, emissions and bunkering.
          Regardless of vessel type, EcoMATE, provides the complete picture you
          need to improve performance while lowering costs. As a result, EcoMATE
          has become the preferred solution for many major shipping companies.
        </Paragraph>
        <Paragraph>
          The EcoMATE Cloud is a shore-based, centralized web reporting tool for
          vessels running the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://krohne.com/en/solutions/monitoring-solutions/ecomate-fuel-emission-monitoring-seagoing-vessels"
          >
            EcoMATE system
          </a>
          . The connected EcoMATE vessels automatically transmit data to the
          cloud, where you can view, download and analyse your data from a
          convenient dashboard summary. The EcoMATE system's four modules work
          in tandem to provide operational users with complete control and
          visibility over important fuel data.
        </Paragraph>
        <Paragraph>
          Shipowners and operators can view trends of fuel consumption or
          emissions in correlation with temperature, speed and/or kilowatt
          thrust per vessel. Additionally a comparison of vessels and bunker
          batch reports can be generated. The measured data can be easily
          downloaded and analysed down to individual fuel consumption point
          level.
        </Paragraph>
      </Container>
    </Box>
    <AngledBox>
      <Container className="body" margin={{ top: "large", bottom: "large" }}>
        <Tabs alignControls="start">
          <Tab title="Product highlights">
            <Box>
              <ul>
                <li>Safe cloud solution for onshore personnel</li>
                <li>
                  Enables ship-owners to monitor all types of vessel within the
                  fleet with a single integrated tool
                </li>
                <li>
                  Full control of fuel consumption, emissions and bunkering
                </li>
                <li>Automated remote data transfer to onshore stations</li>
                <li>
                  Access to measured data down to individual fuel consumers
                </li>
                <li>Conducts data quality checks and validations</li>
                <li>
                  Configurable model with various fuel consumers identified
                </li>
                <li>Collects data from external systems</li>
                <li>Logging of historical data</li>
                <li>Full data integrity</li>
              </ul>
            </Box>
          </Tab>
          <Tab title="Typical applications">
            <Box>
              <ul>
                <li>
                  Monitoring and reporting of fuel consumption measured for main
                  engine and other consumers onboard
                </li>
                <li>
                  Monitoring and reporting of bunker quantities received,
                  measured in bunkering line
                </li>
                <li>For all types of vessels</li>
              </ul>
            </Box>
          </Tab>
        </Tabs>
      </Container>
    </AngledBox>
  </Layout>
)

export default AboutUsPage
